import Vue from 'vue'
import VueRouter from 'vue-router'
import PriceView from '../views/PricesView.vue'
import LoginView from '../views/LoginView.vue'
import MainView from '../views/MainView'
import InvoicesView from '../views/InvoicesView'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'main',
        component: PriceView
    },
    {
        path: '/price',
        name: 'price',
        component: PriceView
    },
    {
        path: '/invoices',
        name: 'invoices',
        component: InvoicesView
    },
    {
        path: '/login',
        name: 'login',
        component: LoginView
    },
    {
        path: '/admin',
        name: 'admin',
        component: () => import(/* webpackChunkName: "admin" */ '../views/AdminView')
    },
    {
        path: '/admin/prices',
        name: 'adminPrices',
        component: () => import(/* webpackChunkName: "adminprices" */ '../views/AdminPrices')
    },
    {
        path: '/admin/edit',
        name: 'editClient',
        component: () => import(/* webpackChunkName: "admin" */ '../views/EditClient')
    },
    {
        path: '/admin/goods',
        name: 'adminGoods',
        component: () => import('../views/AdminGoods')
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
