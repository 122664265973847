<template>
    <div class="goods">
        <div class="gImage">
            <img :src="img">
        </div>
        <div class="gText" v-html="txt"></div>
    </div>
</template>
<script>
export default {
    data() {
        return {}
    },
    props: ['img', 'txt'],
    name: "goodsCart"
}
</script>
<style scoped>
.gImage>img {
    max-width: 500px;
    max-height: 300px;
}

.gImage {
    text-align: center;
}

.gText {
    padding: 5px 20px;
}

.goods {
    padding: 5px;
    border: 1px solid #a0a0a0;
    border-radius: 10px;
    width: 510px;
}
</style>