<template>
	<div id="app">
		<b-navbar toggleable="lg" type="dark" variant="dark" v-if="$route.path !== '/login'">
			<b-navbar-brand to="/">Фірма ЛАКС</b-navbar-brand>
			<b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
			<b-collapse id="nav-collapse" is-nav>
				<b-navbar-nav v-if="$store.state.group !== 'admin'">
					<b-nav-item to="/price">Прайс</b-nav-item>
					<b-nav-item to="/invoices">Замовлення</b-nav-item>
				</b-navbar-nav>
				<b-navbar-nav v-else>
					<b-nav-item to="/admin">Клієнти</b-nav-item>
					<b-nav-item to="/admin/prices">Прайси</b-nav-item>
					<b-nav-item to="/admin/goods">Товари</b-nav-item>
				</b-navbar-nav>
				<b-navbar-nav class="ml-auto">
					<!-- <b-icon icon="person-circle" variant="light" font-scale="2"></b-icon> -->
					<b-nav-item-dropdown right>
						<template #button-content>
							<b-icon icon="person-circle"></b-icon>
						</template>
						<b-dropdown-item @click="logout">Вихід</b-dropdown-item>
					</b-nav-item-dropdown>
				</b-navbar-nav>
			</b-collapse>
		</b-navbar>
		<router-view />
	</div>
</template>
<script>
import fetched from './helper/fetched'
import authHelper from './helper/auth'
export default {
	data() {
		return {}
	},
	methods: {
		logout() {
			authHelper.clearSession()
			// this.$store.commit('clearSession')
			this.$router.push('/login')
		}
	},
	async created() {
		//Check token
		authHelper.checkSession()
	},
	async mounted() {
		const res = await fetched.get(this.$apiUrl + 'check')
	}
}
</script>
<style>
#app {
	font-family: Avenir, Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	color: #2c3e50;
}
</style>
